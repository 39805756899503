import {SupportedContentItemTypes} from "./SchoolFeedPostManager";
import {Dispatch, SetStateAction, useContext} from "react";
import styles from './styles/ContentItemSelect.module.scss';
import FlatIcon from "../../utilities/FlatIcon";
import {useGridType} from "../../../hooks/useGridType";
import {GridTypes} from "../../../pages/MainGridPage";
import {StoreContext} from "../../../stores/StoreLoader";

export function ContentItemSelect(props: {
    setContentItemType: Dispatch<SetStateAction<SupportedContentItemTypes>>,
}) {
    const {sidebarStore, modalStore} = useContext(StoreContext);
    const gridType = useGridType();
    return <div className={styles.container}>
        <p>Select the type of content you'd like to add.</p>
        <ul>
            <li>
                <button onClick={() => props.setContentItemType(SupportedContentItemTypes.NEWS)}>
                    <FlatIcon name={'flaticon-newspaper'}/>
                    <span>News</span>
                </button>
            </li>
            <li>
                <button onClick={async () => {
                    await sidebarStore.close();
                    modalStore.addModal({
                        id: "addOrEditAlert",
                        type: "addOrEditAlert",
                    })
                }}>
                    <FlatIcon name={'flaticon-bell'}/>
                    <span>{process.env.MESSAGING_ENABLED ? "Message/Post" : "Notify"}</span>
                </button>
            </li>
            {gridType === GridTypes.terraced && <li>
                <button onClick={() => props.setContentItemType(SupportedContentItemTypes.MEDIA)}>
                    <FlatIcon name={'flaticon-image'}/>
                    <span>Image</span>
                </button>
            </li>}
            <li>
                <button onClick={() => sidebarStore.setSidebar({
                    view: "AddSocial",
                    sbtype: 'GOOGLE_DRIVE',
                })}>
                    <FlatIcon name={'flaticon-folder'}/>
                    <span>File or Folder</span>
                </button>
            </li>
            <li>
                <button onClick={() => sidebarStore.setSidebar({
                    view: "AddSocial",
                    sbtype: 'ICAL',
                })}>
                    <FlatIcon name={'flaticon-calendar'}/>
                    <span>Calendar</span>
                </button>
            </li>
            <li>
                <button onClick={() => props.setContentItemType(SupportedContentItemTypes.EVENT)}>
                    <FlatIcon name={'flaticon-event'}/>
                    <span>Event</span>
                </button>
            </li>


        </ul>
    </div>
}
