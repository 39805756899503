import styles from "../styles/SchoolFeedWidget.module.scss";
import FontAwesome from "../../utilities/FontAwesome";
import {Dispatch, SetStateAction, useContext} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import WatsonApi from "../../../backends/WatsonApi";
import {IMinimalOrganization} from "./SchoolFeedPostManager";

export function SharePathsEditor(props: {
    selectedOrganizations: IMinimalOrganization[],
    shareRequestOrganizations: IMinimalOrganization[],
    contentItem: ContentItem | null,
    setOrganizationId: Dispatch<SetStateAction<string | undefined>>,
    setShareRequestOrganizations: Dispatch<SetStateAction<IMinimalOrganization[]>>,
    setSelectedOrganizations: Dispatch<SetStateAction<IMinimalOrganization[]>>,
}) {
    const {modalStore, organizationStore} = useContext(StoreContext);

    async function setSelectedOrganizations(orgs: IMinimalOrganization[]) {
        const client = await WatsonApi();
        const response = await client.apis["content-items"].content_items_validate_share_org_ids({
            organization_id: props.contentItem?.organization_id || organizationStore.currentOrganization.id,
            content_item_id: props.contentItem?.id,
            share_org_ids: orgs.map(o => o.id),
        });
        const result: Array<{
            title: string,
            id: string,
            path: string,
            requires_request: boolean,
            is_primary: boolean,
        }> = JSON.parse(response.data);
        const selectedOrgs: IMinimalOrganization[] = [];
        const requestOrgs: IMinimalOrganization[] = [];
        result.forEach(org => {
            if (org.requires_request) {
                requestOrgs.push(org);
            } else {
                selectedOrgs.push(org);
            }
        });
        props.setOrganizationId(result.find(o => o.is_primary)?.id);
        props.setSelectedOrganizations(selectedOrgs);
        props.setShareRequestOrganizations(requestOrgs);
    }

    return <div className={styles.postAssignmentContainer}>
            {props.selectedOrganizations.length === 0 && <div>
                <small>Must select at least one organization that you can edit.</small>
            </div>}
            {props.selectedOrganizations.length + props.shareRequestOrganizations.length > 0 && <>Displays
                on {props.selectedOrganizations.map(org => <span data-is-request={false}>
                                {org.title}
                    <button onClick={() => {
                        props.setSelectedOrganizations(prev => prev.filter(o => o.id !== org.id));
                    }}><FontAwesome prefix={"fas"} name={"fa-times"}/></button>
                            </span>)}{props.shareRequestOrganizations.map(org => <span data-is-request={true}>
                                    <span className={styles.tooltip}
                                          data-content={"Pending approval by organization admin"}><FontAwesome
                                        name={"fa-solid fa-circle-info"}/></span> {org.title}
                    <button onClick={() => {
                        props.setShareRequestOrganizations(prev => prev.filter(o => o.id !== org.id));
                    }}><FontAwesome prefix={"fas"} name={"fa-times"}/></button>
                                </span>)}</>}
            <button onClick={() => {
                modalStore.addModal({
                    type: "organizationSelection",
                    initialSelectedOrganizations: [...props.selectedOrganizations, ...props.shareRequestOrganizations],
                    setOrganizations: setSelectedOrganizations,
                    multiSelect: true,
                    allowSelectNonEditorOrganizations: true,
                    context: "sharepaths",
                })
            }}>
                Select Locations <FontAwesome prefix={'fas'} name={'fa-chevron-right'}/>
            </button>
        </div>
}
