import {Dispatch, SetStateAction, useCallback, useContext} from "react";
import {RequiredDiv} from "../../blocks/SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredDiv";
import styles from "../styles/SchoolFeedWidget.module.scss";
import FontAwesome from "../../utilities/FontAwesome";
import FlatIcon from "../../utilities/FlatIcon";
import * as React from "react";
import {SupportedContentItemTypes} from "./SchoolFeedPostManager";
import NotificationManager from "../../notifications/NotificationManager";
import {compressImage} from "../../../utils/DataUtilities";
import WatsonApi from "../../../backends/WatsonApi";
import {StoreContext} from "../../../stores/StoreLoader";

export function ImageEditor(props: {
    image: string,
    file: IGoogleDriveFile | null,
    setImage: Dispatch<SetStateAction<string>>,
    uploading: boolean,
    setUploading: Dispatch<SetStateAction<boolean>>,
    setUploadError: Dispatch<SetStateAction<string | null>>,
    contentItemType: SupportedContentItemTypes | null,
}) {
    const {organizationStore} = useContext(StoreContext);

    const allowUploadImage = props.contentItemType === SupportedContentItemTypes.MEDIA ||
        props.contentItemType === SupportedContentItemTypes.NEWS;

    async function uploadImage(file) {
        try {
            const client = await WatsonApi()
            const result = await client.apis.organizations.organizations_content_items_upload_image({
                organization_pk: organizationStore.currentOrganization.id,
                id: null,
                file,
            })
            if (result.body.image) {
                props.setImage(result.body.image);
            }
            props.setUploading(false);
            return JSON.parse(result.data)
        } catch (error) {
            props.setUploadError(error.message);
            NotificationManager.error(error.message);
            props.setUploading(false);
        }
    }

    const processFile = useCallback(async file => { // need to pass in a file to handle image editor case
        props.setUploading(true);
        props.setUploadError(null);
        try {
            const compressedFile = await compressImage(file, 2);
            file = new File([compressedFile], compressedFile.name);
            await uploadImage(file);
        }
        catch (error) {
            props.setUploadError(error.message);
            NotificationManager.error(error.message);
            props.setUploading(false);
        }
    }, [props.file]);

    return <>
        <input onChange={async e => {
            if (e.target.files?.[0]) {
                await processFile(e.target.files[0])
            }
        }} id={"sf-image-upload"} hidden type={'file'}
               accept={".jpeg,.jpg,.png,.gif"}/>
        {allowUploadImage &&
            <RequiredDiv valid={!!props.image}
                         required={props.contentItemType === SupportedContentItemTypes.MEDIA}>
                <label title={"Add an image"}
                       className={(props.image || props.uploading) ? styles.disabled : ""}
                       htmlFor={"sf-image-upload"}>
                    {props.uploading ?
                        <FontAwesome prefix={'fas'} name={'fa-spinner'} size={"2x"} spin/> :
                        <FlatIcon name={'flaticon-image'}/>}
                    Image
                </label>
            </RequiredDiv>
        }
    </>
}
